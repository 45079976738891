export const EquipmentModel = () => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
            md: 4
        },
        equipment_key: {
            type: 'input',
            value: '',
            md: 4
        },
        preview: {
            type: 'input',
            validationRules: 'required',
            value: '',
            md: 4
        },
    }
};
