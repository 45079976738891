import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {SeriesFilter} from "../../models/Series";
import Button from "react-bootstrap/lib/Button";
import API from "@aws-amplify/api";
import {NavLink} from "react-router-dom";


export default class Series extends Component {
    static contextType = ApiConstantsContext;
    constructor(props) {
        super(props);
        this.addPath = '/admin/series/add';
        this.editPath = '/admin/series/edit';
        this.state = {
            key: 0
        }
    }

    updatePublish = (event, series_id, published) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/series/${series_id}`

        API.patch('admin', url, {'body': {"published": published}})
            .then(data=> {
                this.props.handleClick(`Successfully set published to ${published}`, "success", "tr");
                this.setState({key: Math.random()});
                console.log(this.state)
            }).catch(error=>{
                this.props.handleClick("Failed to set published: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };

    render() {
        let constants = this.context.constants;
        let c = this.context.get_const;
        return (
            <List
                key={this.state.key}
                name="Series"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/series"
                listApiUrl='/admin/series'
                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }
                    },
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Series gender',
                        row: 'gender',
                        normalizer: (data) => {
                            return c('series_gender', data.gender)
                        }
                    },
                    {col: 'Completed count', row: 'completed_count'},
                    {col: 'Active users', row: 'active_users'},
                    {
                        col: 'Fitness levels',
                        row: 'fitness_levels',
                        normalizer: (data) => {
                            return data.fitness_levels.map((a, i) =>
                                <div key={i}>{`${i + 1}. ${a}`}</div>)
                        }
                    },
                    {
                        col: 'Body part groups',
                        row: 'body_part_groups',
                        normalizer: (data) => {
                            return data.body_part_groups.map((a, i) =>
                                <div key={i}>{`${i + 1}. ${a}`}</div>)
                        }
                    },
                    {
                        col: 'Workouts',
                        row: 'workouts',
                        normalizer: (data) => {
                            return data.workouts.map((a, i) =>
                                <div key={i}>{`${i + 1}. ${a.internal_name}`}</div>)
                        }
                    },
                    {
                        col: 'Is Recommended',
                        row: 'is_recommended',
                        normalizer: (data) => {
                            let is_recommended = 'no'
                            if (data.is_recommended === true){
                                is_recommended = "yes";
                            }
                            return is_recommended
                        }
                    },
                    {
                        col: 'Special',
                        row: 'special',
                        normalizer: (data) => {
                            let special = 'no';
                            if (data.special === true){
                                special = "yes";
                            }
                            return special;
                        }
                    }

                ]}
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    tag: data,
                                }
                            }}
                            className="btn btn-primary"
                        >
                            Edit
                        </NavLink>
                    },
                    (list, data) => {
                        if (data.published === true) {
                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.updatePublish(e, data.id, false)}
                            >
                                Unpublish
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.updatePublish(e, data.id, true)}
                            >
                                Publish
                            </Button>
                        }
                    }
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={SeriesFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
