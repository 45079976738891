import React from 'react';
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {SeriesModel} from "../../models/Series";
import {API} from "aws-amplify";
import {constToSelectOptions} from "../../utils/Utils";
import debounce from "debounce-promise";

export default class SeriesAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Series"
        this.url = "/admin/series"
        this.state = {
            tags: []
        }
        this.debouncedLoadOptions = debounce(this.loadWorkouts, 2000);
    }

    detectSearchParameter = input => {
        input = input.toLowerCase()
        let enums = [
            "name"
        ]
        if (!isNaN(input)) {
            return ["ids", JSON.stringify([parseFloat(input)])]
        }
        for (const enumKey of enums) {
            for (let [key, value] of Object.entries(constToSelectOptions(this.context.constants, enumKey))) {
                if (value.toLowerCase().includes(input)) {

                    return [enumKey, key]
                }
            }
        }
        return ["name", input]
    }

    loadWorkouts = input => {
        let queryParams = {
            "per_page": "100"
        }

        if (input.length > 0) {
            let [searchParam, searchValue] = this.detectSearchParameter(input)
            queryParams[searchParam] = searchValue
        }
        return API.get('admin', "/admin/workouts", {
            'queryStringParameters': queryParams
        })
            .then(data => {
                let  workouts = data.items.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(workouts);
            }).catch(error => {
                console.error(error)
                return []
            })
    }
    componentDidMount() {
        API.get('admin', "/admin/series/tags")
            .then(data => {
                let tags = data.items.reduce(function (obj, item) {
                    obj[item.id] = `[${item.id}] ${item.name}`;
                    return obj;
                }, {});
                this.setState({
                    tags: tags

                })
            }).catch(error => {
            console.error(error)
        })
    }
    getEntity = () => {
        return SeriesModel(
            this.context.constants,
            this.debouncedLoadOptions,
            this.state.tags,
        );
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}