import React, {Component} from "react";
import Card from "components/Card/Card.jsx";

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.object = this.props.prototype;
        this.state = {
            data: [],
            pagination_meta: {
                page: 1,
                per_page: 20,
                total_pages: 0,
                total_items: 0
            },
            items: this.props.value,
            filterParams: {},
            isLoading: true,
            selectedFile: null,
            fileData: null,
        };
        this.uploadAcceptType='.csv'
    }
    base64Converter = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    convertFileToBase64 = () => {
        this.base64Converter(this.state.selectedFile, (result) => {
            let payload = result.split('base64,')[1]
            if (payload) {
                this.setState({fileData: payload}, () => {
            this.props.onChange(this.state.fileData, this.props.name)
        });
        }
        });
    }

    fileSelectedHandler = event => {
        this.state.selectedFile = event.target.files[0]
        this.convertFileToBase64()
    }

  render() {
        return (
            <Card
                    content={
                        <div className="clearfix">
                            <div className="float-container"
                                 style={{height: 100, float: "left"}}>
                                <input type="file" accept={this.props.uploadAcceptType}
                                       onChange={this.fileSelectedHandler}
                                       className="form-group"/>
                            </div>
                        </div>
                    }/>
        )
    }
}

export default FileUploader;
