import React from 'react';
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {SeriesTagModel} from "../../models/SeriesTag";

export default class SeriesTagAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Series Tag"
        this.url = "/admin/series/tags"

        // this.state = {
        //     exercises: [],
        //     tags: [],
        // }
    }

    getEntity = () => {
        return SeriesTagModel();
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}