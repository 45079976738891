import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const ExerciseKeys = [
    'body_part',
    'fitness_level',
];

export const ExerciseModel = (constants, equipment) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },

        description: {
            type: 'input',
            validationRules: '',
            value: '',
        },

        hide_name: buildSelector(constToSelectOptions(constants, 'admin_bool'), 'select'),

        body_parts: {
            ...buildSelector(constToSelectOptions(constants, 'body_part'), 'multiSelect'),
            value: [],
            validationRules: ''
        },
        media_type: buildSelector(constToSelectOptions(constants, 'media_type'), 'select'),
        media_url: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },

        equipment: {
            ...buildSelector(
                equipment,
                'multiSelect',
                (data) => {
                    if(data !== null && data!==undefined) {
                return []
            }
                    return data.map(a => parseInt(a.value));
                },
                undefined,
            ),
            validationRules: ''
        },

        pace: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        mets: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        exercise_level: buildSelector(constToSelectOptions(constants, 'fitness_level'), 'select'),
        exercise_type: buildSelector(constToSelectOptions(constants, 'exercise_type'), 'select'),
        model_gender: buildSelector(constToSelectOptions(constants, 'model_gender'), 'select'),

        audio_name: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        audio_instruction: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        audio_background: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },

    };
}

export const ExerciseFilter = (constants) => {
    let model = {};

    for (const field_name of ExerciseKeys) {
        model[field_name] = buildSelector(constToSelectOptions(constants, field_name))
    }
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
        ...model,

    };
};


export const ExerciseEditModel = (constants, equipment) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },


        body_parts: {
            ...buildSelector(constToSelectOptions(constants, 'body_part'), 'multiSelect'),
            value: [],
            validationRules: ''
        },
        media_type: buildSelector(constToSelectOptions(constants, 'media_type'), 'select'),
        media_url: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        hide_name: buildSelector(constToSelectOptions(constants, 'admin_bool'), 'select'),
        equipment: {
            ...buildSelector(
                equipment,
                'multiSelect',
                (data) => {
                    if (data === null) {
                return []
            }
                    return data.map(a => parseInt(a.value));
                },
                undefined,
            ),
            validationRules: ''
        },

        pace: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        mets: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        exercise_level: buildSelector(constToSelectOptions(constants, 'fitness_level'), 'select'),
        exercise_type: buildSelector(constToSelectOptions(constants, 'exercise_type'), 'select'),
        model_gender: buildSelector(constToSelectOptions(constants, 'model_gender'), 'select'),

    };
}