import React from 'react';
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {BackgroundMusicModel} from "../../models/BackgroundMusic";

export default class BackgroundMusicAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Background Music"
        this.url = "/admin/background-music"
    }

    getEntity = () => {
        return BackgroundMusicModel();
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}