import {changeCheck} from "../utils/Utils";


export const SeriesTranslationModel = (series_id, language_id, name, description, audio_localization) => {
    return {
        series_id: {
            value: series_id,
            hidden: true
        },
        language: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },

        description: {
            type: 'input',
            validationRules: '',
            value: description,
        },
        audio_localization: {
            type: 'input',
            value: audio_localization,
            inputType: 'checkbox',
            selectProps: changeCheck(audio_localization),
            onChangeEvent: 'handleChecked',
            checked: audio_localization,
            md: 1,
        }

    };
}

export const SeriesTranslationFilter = () => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        name: {
            type: 'input',
            value: '',
        },
    };
}
