import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {TagsTranslationModel} from "../../../models/TagsTranslation";

export default class TagsTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Tags Translation"
        this.url = "/admin/translations/series/tags"
        this.skipPrepopulation = true
    }

    locationState = () => {
        return this.props.location.state || {language: {}, translation: {}}
    }

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`
    }

    getEntity = () => {
        let state = this.locationState()
        let translation = state.translation || {}

        return TagsTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || null,

        );
    }
}
