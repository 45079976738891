import React, {Component} from "react";
import {Col, Grid, Row} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import SimpleReactValidator from 'simple-react-validator';
import Loader from "../Loader/Loader";
import FormRow from "./FormRow";
import {API} from 'aws-amplify';
import { changeValue} from "../../utils/Utils";


class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            responseData: {},
            resourceData: {},
            defaults: {}
        }
        this.validator = new SimpleReactValidator({className: 'text-danger'});
    }

    getDefaultValues = async (component) => {
        let entityDefaults = {};
        Object.keys(this.props.entity).filter(value => value !== "__validator__").map((key) => {
            return entityDefaults[key] = this.props.entity[key].value
        });
        // Response maps against {key: label} dict which may not exist
        // at this moment (still being fetched over the Internet)
        let responseData = this.props.responseData || this.state.responseData
        if (Object.keys(responseData).length !== 0) {
            await this.mapData('responseNormalizer', entityDefaults, responseData, this.props.entity)
        }
        Object.assign(entityDefaults, this.state.resourceData)
        if (component !== undefined && this.props.entity.__validator__ !== undefined) {
            this.props.entity.__validator__(component, entityDefaults)
        }
        return entityDefaults
    }

    prepareRequestData = async () => {
        let requestData = {};
        await this.mapData('requestNormalizer', requestData, await this.getDefaultValues(), this.props.entity)
        return requestData
    };

    handleSubmit = async event => {
        event.persist()
        if (this.validator.allValid()) {
            event.preventDefault();
            let requestData = {'body': await this.prepareRequestData()};
            let actionUrl = (this.props.actionUrl || this.props.url) + (this.props.id ? "/" + this.props.id : '');
            let resultPromise = this.props.id
                ? API.put('admin', actionUrl, requestData)
                : API.post('admin', actionUrl, requestData);

            resultPromise.then(data => {
                if (this.props.onSuccess) {
                    event.preventDefault();
                    this.props.onSuccess()
                }
            }).catch(error => {
                this.props.handleClick(error.response.data.error || error.response.data.message, "error", "tr");
            });
        } else {
            this.validator.showMessages();
            event.preventDefault();
            this.forceUpdate();
        }
    };

    handleInput = event => {
        const {target: {name, value}} = event
        this.setState(prevState => {
            let resourceData = Object.assign({}, prevState.resourceData);
            resourceData[name] = value;
            return {resourceData};
        }, () => this.reloadDefaults(name))
    };
    handleSelect = (value, event) => {
        this.setState(prevState => {
            let resourceData = Object.assign({}, prevState.resourceData);
            resourceData[event.name] = value;
            return {resourceData};
        }, () => this.reloadDefaults(event.name))
    };
    handleChecked = event => {
        event.persist()
        let {target: {name, value}} = event
        this.setState(prevState => {
            let resourceData = Object.assign({}, prevState.resourceData);
            value = changeValue(value, event)
            resourceData[name] = value;
            return {resourceData};
        }, () => this.reloadDefaults(name))
    };
    handleCollection = (_State, name) => {
        this.setState(prevState => {
            let resourceData = Object.assign({}, prevState.resourceData);
            resourceData[name] = _State;
            return {resourceData};
        }, () => this.reloadDefaults(name))
    };

    mapData = async (normalizer, result, data, object) => {
        for (const key of Object.keys(object).filter(value => value !== "__validator__")) {
            let currentElement = data[key];
            if (object[key].prototype !== undefined) {
                let childs = currentElement
                let proto = object[key].prototype

                currentElement = await Promise.all(Object.keys(childs).map(async (key) => {
                    let child = {}
                    await this.mapData(normalizer, child, childs[key], proto)
                    return child
                }))
            }
            result[key] = object[key][normalizer] === undefined
                ? currentElement
                : await Promise.resolve(object[key][normalizer](currentElement)).then(
                    value => {
                        return value
                    }
                );
        }
    }

    reloadDefaults(component) {
        this.getDefaultValues(component).then(
            value => {
                this.setState({defaults: value})
            }
        )
    }

    componentDidMount() {
        if (!this.props.skipPrepopulation && this.props.id) {
            API.get('admin', this.props.getEntityUrl || this.props.url + "/" + this.props.id)
                .then(data => {
                    this.setState({responseData: data})

                    this.getDefaultValues().then(
                        value => {
                            this.setState({defaults: value, isLoading: false})
                        }
                    )
                });
        } else {
            this.getDefaultValues().then(
                value => {
                    this.setState({defaults: value, isLoading: false})
                }
            )
        }
    }

    render() {
        let defaults = this.state.defaults
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={this.props.title}
                                content={
                                    <Loader isLoading={this.state.isLoading}>
                                        <form onSubmit={this.handleSubmit}>
                                            {Object.keys(this.props.entity).filter(value => value !== "__validator__").map((key, index) => {
                                                if (!this.props.entity[key].hidden) {
                                                    return <FormRow
                                                        key={index}
                                                        name={key}
                                                        type={this.props.entity[key].type || 'input'}
                                                        inputType={this.props.entity[key].inputType}
                                                        value={defaults[key]}
                                                        validationRules={this.props.entity[key].validationRules || ''}
                                                        onChangeEvent={this[this.props.entity[key].onChangeEvent] || this.handleInput}
                                                        selectOptions={this.props.entity[key].selectOptions || null}
                                                        prototype={this.props.entity[key].prototype || null}
                                                        validator={this.validator}
                                                        md={this.props.entity[key].md || null}
                                                        isDisabled={this.props.entity[key].isDisabled || false}
                                                        selectProps={this.props.entity[key].selectProps}
                                                    />
                                                }
                                                return null
                                            })}
                                            <Button bsStyle="primary"
                                                    type="submit"
                                                    onClick={async (e) => this.handleSubmit(e)}>
                                                Save
                                            </Button>
                                            <div className="clearfix"/>
                                        </form>
                                    </Loader>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Form;
