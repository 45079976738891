import {buildSelector, constToSelectOptions} from "../utils/Utils";
import {ContentExerciseModel} from "./YogaWbExercise";

export const ContentDownloadModel = constants => {
    return {
        content_table: buildSelector(
            constToSelectOptions(constants, 'content_table'),
            'select',
            (data) => {
                return data.value;
            },
        ),
        filters:{
            type: 'table_collection',
            value: [],
            prototype: ContentDownloadFilter(constants),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
        },
        emails: {
            type: 'input',
            value: '',
            validationRules: 'required',
            placeholder: 'Emails separated by comma',
            requestNormalizer: (data) => {
                return JSON.stringify(data.split(','));
            }
        }
    }
};


export const ContentDownloadFilter = (constants) => {
    return {
        yoga_type: {
            ...buildSelector(constToSelectOptions(constants, 'yoga_type'), 'select'),
            value: null,
            validationRules: ''
        },
        from_date: {
            type: 'text',
            value: null,
            validationRules: '',
        },
        to_date: {
            type: 'text',
            value: null,
            validationRules: ['', {'regex': "^\\d{4}-\\d{2}-\\d{2}$"}],
        },
    };
};
