export const BackgroundMusicModel = () => {
    return {
        audio_url: {
            type: 'input',
            validationRules: 'required',
            value: ''
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: ''
        },
    }
};

export const BackgroundMusicTranslationModel = (music_id, language_id, name) => {
    return {
        music_id: {
            value: music_id,
            hidden: true
        },
        language: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },

    };
}

export const BackgroundMusicFilter = (constants) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        name: {
            type: 'input',
            value: '',
            placeholder: 'Music name'
        }
    }
};