import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ExerciseModel} from "../../models/Exercise";
import {API} from "aws-amplify";


export default class ExerciseAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.state = {
            equipment: []
        }
        this.name = "Exercise"
        this.url = "/admin/exercises"
        this.redirectUrl = "/admin/exercises"
    }

    componentDidMount() {
        API.get('admin', "/admin/equipment")
            .then(data => {
                let equipment = data.items.reduce(function (obj, item) {
                    obj[item.id] = `[${item.id}] ${item.name}`;
                    return obj;
                }, {});
                this.setState({
                    equipment: equipment

                })
            }).catch(error => {
            console.error(error)
        })
    }

    getEntity = () => {
        return ExerciseModel(this.context.constants,
            this.state.equipment);
    }
}
