export const ExerciseTranslationModel = (exercise_id, language_id, name, audio_name, audio_instruction, audio_background, description) => {
    return {
        exercise_id: {
            value: exercise_id,
            hidden: true
        },
        language_id: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },

        description: {
            type: 'input',
            validationRules: '',
            value: description,
        },

        audio_name: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: audio_name,
        },
        audio_instruction: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: audio_instruction,
        },
        audio_background: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: audio_background,
        },
    };
}

export const ExerciseContentTranslationModel = (exercise_content_id, language_id, name, audio_name, audio_instruction, audio_background, description) => {
    return {
        exercise_content_id: {
            value: exercise_content_id,
            hidden: true
        },
        language_id: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },

        description: {
            type: 'input',
            validationRules: '',
            value: description,
        },

        audio_name: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: audio_name,
        },
        audio_instruction: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: audio_instruction,
        },
        audio_background: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: audio_background,
        },
    };
}

export const ExerciseTranslationFilter = () => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        name: {
            type: 'input',
            value: '',
        },
    };
}