import React, {Component} from "react";
import List from "../../components/List/List";
import API from "@aws-amplify/api";
import Button from "react-bootstrap/lib/Button";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {ExerciseFilter} from "../../models/Exercise";
import {constList} from "../../utils/Utils";
import {NavLink} from "react-router-dom";

export default class Exercise extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/exercises/add';
        this.editPath = '/admin/exercises/edit';
        this.state = {
            key: 0
        }
    }
    hideExerciseName = (event, exercise_id, hide) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/exercises/${exercise_id}`

        API.patch('admin', url, {'body': {"hide_name": hide}})
            .then(data=> {
                this.props.handleClick(`Successfully set hide_name to ${hide}`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to set hide_name: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };
    render() {
        let constants = this.context.constants;
        let c = this.context.get_const;
        return (
            <List
                key={this.state.key}
                name="Exercises"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/exercises"
                listApiUrl='/admin/exercises'
                uploadApiUrl='/admin/exercises/csv'
                instructionUrl="https://welltech.atlassian.net/wiki/spaces/YO/pages/3974299795/Upload+Exercises+for+Content-Service+Admin"
                uploadAcceptType='.csv'
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    tag: data,
                                }
                            }}
                            className="btn btn-primary"
                        >
                            Edit
                        </NavLink>
                    },
                    (list, data) => {
                        if (data.hide_name === 'true') {
                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.hideExerciseName(e, data.id, false)}
                            >
                                Make name visible
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.hideExerciseName(e, data.id, true)}
                            >
                                Make invisible name
                            </Button>
                        }
                    }
                ]}
                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }
                    },
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Body Parts',
                        row: 'body_parts',
                        normalizer: (data) => {
                            return constList(c, data, 'body_parts', 'body_part');
                        }
                    },

                    {
                        col: 'Exercise Level',
                        row: 'exercise_level',
                        normalizer: (data) => {
                            return c('fitness_level', data.exercise_level)
                        }
                    },
                    {
                        col: 'Exercise Type',
                        row: 'exercise_type',
                        normalizer: (data) => {
                            return c('exercise_type', data.exercise_type)
                        }
                    },
                    {col: 'Pace', row: 'pace'},
                    {col: 'Mets', row: 'mets'},

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={ExerciseFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
