import Workout from "./controllers/Workouts/Workout.jsx";
import WorkoutAdd from "./controllers/Workouts/WorkoutAdd.jsx";
import WorkoutEdit from "./controllers/Workouts/WorkoutEdit";
import SeriesTranslation from "./controllers/Translation/Series/Series";
import SeriesTranslationAdd from "./controllers/Translation/Series/SeriesAdd";
import SeriesTranslationEdit from "./controllers/Translation/Series/SeriesEdit";
import defaults from "./defaults";
import Series from "./controllers/Series/Series";
import SeriesAdd from "./controllers/Series/SeriesAdd";
import SeriesEdit from "./controllers/Series/SeriesEdit";
import SeriesTag from "./controllers/SeriesTag/SeriesTag";
import SeriesTagAdd from "./controllers/SeriesTag/SeriesTagAdd";
import SeriesTagEdit from "./controllers/SeriesTag/SeriesTagEdit";
import ReviewerInfo from "./controllers/ReviewerInfo/ReviewerInfo";
import ReviewerInfoAdd from "./controllers/ReviewerInfo/ReviewerInfoAdd";
import ReviewerInfoEdit from "./controllers/ReviewerInfo/ReviewerInfoEdit";
import TagsTranslationAdd from "./controllers/Translation/Tags/TagsAdd";
import TagsTranslationEdit from "./controllers/Translation/Tags/TagsEdit";
import TagsTranslation from "./controllers/Translation/Tags/Tags";
import UserToken from "./controllers/Users/UserToken";
import BackgroundMusic from "./controllers/BackgroundMusic/BackgroundMusic";
import BackgroundMusicAdd from "./controllers/BackgroundMusic/BackgroundMusicAdd";
import BackgroundMusicEdit from "./controllers/BackgroundMusic/BackgroundMusicEdit";
import BackgroundMusicTranslation from "./controllers/Translation/BackgroundMusic/BackgroundMusic";
import BackgroundMusicTranslationAdd from "./controllers/Translation/BackgroundMusic/BackgroundMusicAdd";
import BackgroundMusicTranslationEdit from "./controllers/Translation/BackgroundMusic/BackgroundMusicEdit";
import Branches from "./controllers/Branches/Branches";
import BranchesAdd from "./controllers/Branches/BranchesAdd";
import BranchesEdit from "./controllers/Branches/BranchesEdit";

// content-admin
import Exercise from "./controllers/Exercise/Exercise.jsx";
import ExerciseAdd from "./controllers/Exercise/ExerciseAdd.jsx";
import ExerciseEdit from "./controllers/Exercise/ExerciseEdit";
import ExerciseTranslation from "./controllers/Translation/Exercise/Exercise";
import ExerciseTranslationAdd from "./controllers/Translation/Exercise/ExerciseAdd";
import ExerciseTranslationEdit from "./controllers/Translation/Exercise/ExerciseEdit";
import BodyPartTranslationAdd from "./controllers/Translation/BodyPart/BodyPartAdd";
import BodyPartTranslation from "./controllers/Translation/BodyPart/BodyPart";
import BodyPartTranslationEdit from "./controllers/Translation/BodyPart/BodyPartEdit";
import YogaTypeTranslationAdd from "./controllers/Translation/YogaType/YogaTypeAdd";
import YogaTypeTranslation from "./controllers/Translation/YogaType/YogaType";
import YogaTypeTranslationEdit from "./controllers/Translation/YogaType/YogaTypeEdit";
import Block from "./controllers/Block/Block";
import BlockAdd from "./controllers/Block/BlockAdd";
import BlockEdit from "./controllers/Block/BlockEdit";
import Equipment from "./controllers/Equipment/Equipment";
import EquipmentAdd from "./controllers/Equipment/EquipmentAdd";
import EquipmentEdit from "./controllers/Equipment/EquipmentEdit";

import YogaMainExercise from "./controllers/WorkoutBuilder/MainExercise";
import YogaMainExerciseAdd from "./controllers/WorkoutBuilder/MainExerciseAdd";
import YogaMainExerciseEdit from "./controllers/WorkoutBuilder/MainExerciseEdit";

import YogaSpecialExercise from "./controllers/WorkoutBuilder/SpecialConnectExercise";
import YogaSpecialExerciseAdd from "./controllers/WorkoutBuilder/SpecialConnectExerciseAdd";
import YogaSpecialExerciseEdit from "./controllers/WorkoutBuilder/SpecialConnectExerciseEdit";

import YogaConnectExercise from "./controllers/WorkoutBuilder/ConnectExercise";
import YogaConnectExerciseAdd from "./controllers/WorkoutBuilder/ConnectExerciseAdd";
import YogaConnectExerciseEdit from "./controllers/WorkoutBuilder/ConnectExerciseEdit";

import MainExerciseTranslation from "./controllers/Translation/MainExercise/MainExercise";
import MainExerciseTranslationAdd from "./controllers/Translation/MainExercise/MainExerciseAdd";
import MainExerciseTranslationEdit from "./controllers/Translation/MainExercise/MainExerciseEdit";

import ConnectExerciseTranslation from "./controllers/Translation/ConnectExercise/ConnectExercise";
import ConnectExerciseTranslationAdd from "./controllers/Translation/ConnectExercise/ConnectExerciseAdd";
import ConnectExerciseTranslationEdit from "./controllers/Translation/ConnectExercise/ConnectExerciseEdit";

import SpecialExerciseTranslation from "./controllers/Translation/SpecialConnectExercise/SpecialConnectExercise";
import SpecialExerciseTranslationAdd from "./controllers/Translation/SpecialConnectExercise/SpecialConnectExerciseAdd";
import SpecialExerciseTranslationEdit from "./controllers/Translation/SpecialConnectExercise/SpecialConnectExerciseEdit";

import LinksChecker from "./controllers/LinksChecker/LinksChecker";
import ContentDownload from "./controllers/ContentDownload/ContentDownload";
import ContentUpload from "./controllers/ContentUpload/ContentUpload";


let g = defaults.userGroups

const dashboardRoutes = [

    {
        exact: true,
        path: "/exercises",
        name: "Exercises",
        icon: "pe-7s-check",
        component: Exercise,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/exercises/add",
        name: "Add Exercise",
        icon: "pe-7s-check",
        component: ExerciseAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/exercises/edit/:id",
        name: "Edit Exercise",
        icon: "pe-7s-check",
        component: ExerciseEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },


    // Blocks
    {
        exact: true,
        path: "/blocks",
        name: "Blocks",
        icon: "pe-7s-box2",
        component: Block,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/blocks/add",
        name: "Block Add",
        icon: "pe-7s-gym",
        component: BlockAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/blocks/edit/:id",
        name: "Block Edit",
        icon: "pe-7s-gym",
        component: BlockEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },


    {
        exact: true,
        path: "/workouts",
        name: "Workouts",
        icon: "pe-7s-check",
        component: Workout,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/workouts/add",
        name: "Add Workouts",
        icon: "pe-7s-check",
        component: WorkoutAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/workouts/edit/:id",
        name: "Edit Workouts",
        icon: "pe-7s-check",
        component: WorkoutEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },


    // Series
    {
        exact: true,
        path: "/series",
        name: "Series",
        icon: "pe-7s-box2",
        component: Series,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/series/add",
        name: "Series Add",
        icon: "pe-7s-gym",
        component: SeriesAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/series/edit/:id",
        name: "Series Edit",
        icon: "pe-7s-gym",
        component: SeriesEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    // Series Tags
    {
        exact: true,
        submenu: true,
        path: "/series/tags",
        name: "Series Tag",
        component: SeriesTag,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/series/tags/add",
        name: "Series Tag Add",
        icon: "pe-7s-gym",
        component: SeriesTagAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/series/tags/edit/:id",
        name: "Series Tag Edit",
        icon: "pe-7s-gym",
        component: SeriesTagEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    // Branches
    {
        exact: true,
        path: "/branches",
        name: "Branches",
        icon: "pe-7s-box2",
        component: Branches,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/branches/add",
        name: "Branch Add",
        icon: "pe-7s-gym",
        component: BranchesAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/branches/edit/:id",
        name: "Branch Edit",
        icon: "pe-7s-gym",
        component: BranchesEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    // Background Music
    {
        exact: true,
        path: "/background-music",
        name: "Background Music",
        icon: "pe-7s-music",
        component: BackgroundMusic,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/background-music/add",
        name: "Background Music Add",
        icon: "pe-7s-music",
        component: BackgroundMusicAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/background-music/edit/:id",
        name: "Background Music Edit",
        icon: "pe-7s-music",
        component: BackgroundMusicEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },

    // Yoga Main Exercise
    {
        exact: true,
        path: "/wb/main/exercises",
        name: "Wb Yoga ",
        icon: "pe-7s-pen",
        component: YogaMainExercise,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        exact: true,
        submenu: true,
        path: "/wb/main/exercises",
        name: "Main Exercises",
        component: YogaMainExercise,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/wb/main/exercises/add",
        name: "",
        component: YogaMainExerciseAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/wb/main/exercises/edit/:id",
        name: "",
        component: YogaMainExerciseEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // Reviewer Info
    {
        exact: true,
        path: "/reviewer-info",
        name: "Reviewer Info",
        icon: "pe-7s-box2",
        component: ReviewerInfo,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        exact: true,
        path: "/reviewer-info/add",
        name: "Reviewer Info Add",
        icon: "pe-7s-box2",
        component: ReviewerInfoAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        exact: true,
        path: "/reviewer-info/edit/:id",
        name: "Reviewer Info Add",
        icon: "pe-7s-box2",
        component: ReviewerInfoEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    // Yoga Connect Exercise
    {
        exact: true,
        submenu: true,
        path: "/wb/connect/exercises",
        name: "Connect Exercises",
        component: YogaConnectExercise,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/wb/connect/exercises/add",
        name: "",
        component: YogaConnectExerciseAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/wb/connect/exercises/edit/:id",
        name: "",
        component: YogaConnectExerciseEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
            // Yoga Special Exercise
    {
        exact: true,
        submenu: true,
        path: "/wb/special/exercises",
        name: "Special Connect Exercises",
        component: YogaSpecialExercise,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/wb/special/exercises/add",
        name: "",
        component: YogaSpecialExerciseAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/wb/special/exercises/edit/:id",
        name: "",
        component: YogaSpecialExerciseEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // Translations series
    {
        exact: true,
        path: "/translations/series",
        name: "Translations",
        icon: "pe-7s-pen",
        component: SeriesTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        exact: true,
        submenu: true,
        path: "/translations/series",
        name: "Series Translations",
        component: SeriesTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/series/add",
        name: "",
        component: SeriesTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/series/edit/:id",
        name: "",
        component: SeriesTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        exact: true,
        submenu: true,
        path: "/translations/series/tags",
        name: "Tags Translations",
        component: TagsTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/series/tags/add",
        name: "",
        component: TagsTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/series/tags/edit/:id",
        name: "",
        component: TagsTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // WB Main Exercise Translation

    {
        exact: true,
        submenu: true,
        path: "/wb/main/translations",
        name: "WB Main Exercises",
        component: MainExerciseTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/wb/main/translations/add",
        name: "",
        component: MainExerciseTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/wb/main/translations/edit/:id",
        name: "",
        component: MainExerciseTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // WB Connect Exercise Translation
    {
        exact: true,
        submenu: true,
        path: "/wb/connect/translations",
        name: "WB Connect Exercises",
        component: ConnectExerciseTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/wb/connect/translations/add",
        name: "",
        component: ConnectExerciseTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/wb/connect/translations/edit/:id",
        name: "",
        component: ConnectExerciseTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // Special Connect Exercise Translation

    {
        exact: true,
        submenu: true,
        path: "/wb/special/translations",
        name: "Special Connect Exercises",
        component: SpecialExerciseTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/wb/special/translations/add",
        name: "",
        component: SpecialExerciseTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/wb/special/translations/edit/:id",
        name: "",
        component: SpecialExerciseTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    {
        exact: true,
        submenu: true,
        path: "/translations/exercises",
        name: "Exercises",
        component: ExerciseTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/exercises/add",
        name: "",
        component: ExerciseTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/exercises/edit/:id",
        name: "",
        component: ExerciseTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        exact: true,
        submenu: true,
        path: "/translations/background-music",
        name: "Background Music Translations",
        component: BackgroundMusicTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/background-music/add",
        name: "",
        component: BackgroundMusicTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/background-music/edit/:id",
        name: "",
        component: BackgroundMusicTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // Translations Body parts
    {
        exact: true,
        submenu: true,
        path: "/translations/body-parts",
        name: "Body Parts",
        component: BodyPartTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/body-parts/add",
        name: "",
        component: BodyPartTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/body-parts/edit/:id",
        name: "",
        component: BodyPartTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // Translations Yoga Types
    {
        exact: true,
        submenu: true,
        path: "/translations/yoga-types",
        name: "Yoga Types",
        component: YogaTypeTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/yoga-types/add",
        name: "",
        component: YogaTypeTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/yoga-types/edit/:id",
        name: "",
        component: YogaTypeTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        exact: true,
        path: "/users/token",
        name: "User Token",
        icon: "pe-7s-user",
        component: UserToken,
        layout: "/admin",
        allowedGroups: [g.admin]
    },
    // Equipment
    {
        exact: true,
        submenu: true,
        path: "/equipment",
        name: "Equipment",
        component: Equipment,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/equipment/add",
        name: "Equipment Add",
        icon: "pe-7s-gym",
        component: EquipmentAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/equipment/edit/:id",
        name: "Equipment Edit",
        icon: "pe-7s-gym",
        component: EquipmentEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    // Content Download
    {
        exact: true,
        path: "/content-download",
        name: "Content Download",
        icon: "pe-7s-cloud-download",
        component: ContentDownload,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
    // Content Upload
    {
        exact: true,
        path: "/content-upload",
        name: "Content Upload",
        icon: "pe-7s-cloud-upload",
        component: ContentUpload,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
        // Links Checker
    {
        exact: true,
        path: "/linkchecker",
        name: "Links Checker",
        icon: "pe-7s-link",
        component: LinksChecker,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },


];

export default dashboardRoutes;
