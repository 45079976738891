import React, {Component} from "react";
import List from "../../components/List/List";
import {UserTokenFilter} from "../../models/UserToken";

class UserToken extends Component {
    render(){
        return (
            <List
                name="UserToken"
                listApiUrl='/admin/user/token'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Email', row: 'email'},
                    {col: 'Token', row: 'token'},
                ]}
                userHasPermission={this.props.userHasPermission}
                filterObject={UserTokenFilter}
            >
            </List>
        )

    }
}

export default UserToken;
