import {buildSelector, constToSelectOptions, generateBadgeRow} from "../utils/Utils";
import {components} from "react-select";
import React from "react";
import {API} from "aws-amplify";


const Option = ({children, ...props}) => {
    let option = props.data

    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-3 pull-left">
                    <img alt={"Preview"} className="pull-left mr-2 img-rounded"
                         width="128"
                         height="128"
                         src={option.preview}/>
                </div>
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.name}`}</strong>
                    </p>
                    {generateBadgeRow("Body parts", option.body_parts)}<br/>
                    {generateBadgeRow("Equipment", option.equipment.name, "success")}<br/>
                    <span style={{"margin": "0"}}>
                        Pace/Mets/One RM: <strong>{option.pace}</strong>/<strong>{option.mets}</strong>/
                    </span><br/>
                    <span style={{"margin": "0"}}>
                        Exercise Level: <strong>{option.fitness_level}</strong>
                    </span><br/>
                </div>
            </div>
        </components.Option>
    );
};

export const BlockExerciseModel = (constants, loadOptions) => {
    let objCache = {}
    return {
        exercise_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: (option) => {
                return option.id
            },
            responseNormalizer: (option) => {
                let exercise_id = option
                if (objCache[exercise_id] !== undefined) {
                    return objCache[exercise_id]
                }
                return API.get('admin', `/admin/exercises/${exercise_id}`)
                    .then(data => {
                        objCache[exercise_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                getOptionLabel: option => {
                    return `[${option['id']}] ${option['name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                components: {Option},
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
            },
            md: 5
        },
        exercise_type: {
            ...buildSelector(
                constToSelectOptions(constants, 'block_exercise_type'),
                'select',
            ),
            md: 2
        },
        value: {
            inputType: 'number',
            validationRules: 'required',
            value: 0,
            requestNormalizer: (data) => {
                return parseFloat(data);
            },
            md: 1
        },

        intro_duration: {
            inputType: 'number',
            validationRules: 'required',
            value: 0,
            md: 1,
            requestNormalizer: (data) => {
                return parseInt(data);
            }
        },
    };
}


export const BlockFilter = (constants) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        name: {
            type: 'input',
            value: '',
        },
    }
};

export const BlockModel = (constants, loadExercises) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
            md: 4
        },
        activity_type: buildSelector(constToSelectOptions(constants, 'block_activity_type'), 'select'),
        block_fitness_level: buildSelector(constToSelectOptions(constants, 'fitness_level'), 'select'),
        block_type: buildSelector(constToSelectOptions(constants, 'block_type'), 'select'),
        block_gender: buildSelector(constToSelectOptions(constants, 'gender'), 'select'),
        exercises: {
            type: 'table_collection',
            value: [],
            prototype: BlockExerciseModel(constants, loadExercises),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
        },
    }
};
