import {buildSelector, constToSelectOptions, yesNoSelector} from "../utils/Utils";
import {components} from "react-select";
import React from "react";
import {API} from "aws-amplify";


export const ReviewerModel = (constants) => {
    return {
        email_address: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        default_password: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        otp: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        published: buildSelector(constToSelectOptions(constants, 'admin_bool'), 'select')
    }
};


export const ReviewerEditModel = (constants) => {
    return {
        email_address: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        default_password: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        otp: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        published: buildSelector(constToSelectOptions(constants, 'admin_bool'), 'select')
    }
};