import {buildSelector, constToSelectOptions, yesNoSelector} from "../utils/Utils";
import {API} from "aws-amplify";
import {components} from "react-select";
import React from "react";


const Option = ({children, ...props}) => {
    let option = props.data

    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-3 pull-left">
                    <img alt={"Preview"} className="pull-left mr-2 img-rounded"
                         width="128"
                         height="128"
                         src={option.preview}/>
                </div>
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.name}`}</strong></p>
                </div>
            </div>
        </components.Option>
    );
};

export const BranchesSeriesModel = (constants, loadOptions) => {
    let objCache = {}
    return {
        series_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data.id);
            },
            responseNormalizer: (option) => {
                let series_id = option
                if (objCache[series_id] !== undefined) {
                    return objCache[series_id]
                }
                return API.get('admin', `/admin/series/${series_id}`)
                    .then(data => {
                        objCache[series_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                getOptionLabel: option => {
                    return `[${option['id']}] ${option['name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                components: {Option},
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
            },
            md: 5
        },
        body_parts: {...buildSelector(
                constToSelectOptions(constants, 'body_part'),
                'multiSelect',
            ),
            value: null,
        validationRules: ''},

    };
}
export const BranchesModel = (constants, loadSeries) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },

        series: {
            type: 'table_collection',
            value: [],
            prototype: BranchesSeriesModel(constants, loadSeries),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
        },
        is_published: yesNoSelector()
    }
};


export const BranchesFilter = () => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        name: {
            type: 'input',
            value: '',
        }
    }
};
