import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {WorkoutModel} from "../../models/Workout";
import {API} from "aws-amplify";
import debounce from "debounce-promise";
import {constToSelectOptions} from "../../utils/Utils";
import React from "react";


export default class WorkoutAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);

        this.debouncedLoadOptions = debounce(this.loadBlocks, 2000);
        this.name = "Workout"
        this.url = "/admin/workouts"
        this.redirectUrl = "/admin/workouts"
    }

    detectSearchParameter = input => {
        input = input.toLowerCase()
        let enums = [
            "name"
        ]
        if (!isNaN(input)) {
            return ["ids", JSON.stringify([parseFloat(input)])]
        }
        for (const enumKey of enums) {
            for (let [key, value] of Object.entries(constToSelectOptions(this.context.constants, enumKey))) {
                if (value.toLowerCase().includes(input)) {

                    return [enumKey, key]
                }
            }
        }
        return ["name", input]
    }

    loadBlocks = input => {
        let queryParams = {
            "per_page": "100"
        }

        if (input.length > 0) {
            let [searchParam, searchValue] = this.detectSearchParameter(input)
            queryParams[searchParam] = searchValue
        }

        return API.get('admin', "/admin/blocks", {
            'queryStringParameters': queryParams
        })
            .then(data => {
                let to_const = (values, key) => {
                    return (values || []).map(value => this.context.get_const(key, value))
                }

                let blocks = data.items.reduce(function (obj, item) {
                    item.body_parts = to_const(item.body_parts, "body_part");
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(blocks);
            }).catch(error => {
            console.error(error)
            return []
        })
    }

    getEntity = () => {
        return WorkoutModel(this.context.constants,
            this.debouncedLoadOptions);

    }
    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}
