import React from 'react';
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {constToSelectOptions} from "../../utils/Utils";
import debounce from "debounce-promise";
import {BranchesModel} from "../../models/Branches";
import {API} from "aws-amplify";

export default class BranchesAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Branches"
        this.url = "/admin/branches"
        this.debouncedLoadOptions = debounce(this.loadSeries, 2000);
    }

    detectSearchParameter = input => {
        input = input.toLowerCase()
        let enums = [
            "name"
        ]
        if (!isNaN(input)) {
            return ["ids", JSON.stringify([parseFloat(input)])]
        }
        for (const enumKey of enums) {
            for (let [key, value] of Object.entries(constToSelectOptions(this.context.constants, enumKey))) {
                if (value.toLowerCase().includes(input)) {

                    return [enumKey, key]
                }
            }
        }
        return ["name", input]
    }

    loadSeries = input => {
        let queryParams = {
            "per_page": "100"
        }

        if (input.length > 0) {
            let [searchParam, searchValue] = this.detectSearchParameter(input)
            queryParams[searchParam] = searchValue
        }
        return API.get('admin', "/admin/series", {
            'queryStringParameters': queryParams
        })
            .then(data => {
                let  series = data.items.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(series);
            }).catch(error => {
                console.error(error)
                return []
            })
    }

    getEntity = () => {
        return BranchesModel(
            this.context.constants,
            this.debouncedLoadOptions,
        );
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}