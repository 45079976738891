import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {SeriesTranslationFilter} from "../../../models/SeriesTranslation";

export default class SeriesTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Series Translations"
        this.addPath = "/admin/translations/series/add";
        this.editPath = "/admin/translations/series/edit";
        this.removeUrl = "/admin/translations/series";
        this.listApiUrl = "/admin/translations/series";
    }

    generateFilter = () => {
        return SeriesTranslationFilter()
    }
}
