export const TagsTranslationModel = (tags_id, language_id, name) => {
    return {
        tags_id: {
            value: tags_id,
            hidden: true
        },
        language: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },

    };
}

export const TagsTranslationFilter = () => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        name: {
            type: 'input',
            value: '',
        },
    };
}