import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const ContentUploadModel = constants => {
    return {
        content_table: buildSelector(
            constToSelectOptions(constants, 'content_uploader_tables'),
            'select',
            (data) => {
                return data.value;
            },
        ),
        upload_process:buildSelector(
            constToSelectOptions(constants, 'upload_process'),
            'select',
            (data) => {
                return data.value;
            },
        ),
        data:{
            type:'csv_uploader',
            value:[],
            onChangeEvent: 'handleCollection',
            validationRules: '',
        }
    }
};