import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {TagsTranslationFilter} from "../../../models/TagsTranslation";

export default class TagsTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Tags Translations"
        this.addPath = "/admin/translations/series/tags/add";
        this.editPath = "/admin/translations/series/tags/edit";
        this.removeUrl = "/admin/translations/series/tags";
        this.listApiUrl = "/admin/translations/series/tags";
    }

    generateFilter = () => {
        return TagsTranslationFilter()
    }
}
