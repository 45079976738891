import {buildSelector, constToSelectOptions} from "../utils/Utils";
import React from "react";
import {components} from "react-select";
import {NavLink} from "react-router-dom";
import {API} from "aws-amplify";

const Option = ({children, ...props}) => {
    let option = props.data
    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}>
                        <NavLink
                            to={{
                                pathname: "/admin/blocks/edit/" + option.id,
                            }}
                            target="_blank"
                            style={{color: 'black'}}
                        >
                            <strong>[{option.id}] {option.name}</strong> <span
                            className="glyphicon glyphicon-new-window"/>
                        </NavLink>
                    </p>
                    <span style={{"margin": "0"}}>
                        Total Time: <strong>{option.total_time}</strong>
                    </span><br/>
                </div>
            </div>
        </components.Option>
    );
};


// const Option = ({children, ...props}) => {
//     let option = props.data
//
//     return (
//         <components.Option {...props}>
//             <div className="clearfix">
//                 <div className="mb-3 pull-left">
//                     <img alt={"Preview"} className="pull-left mr-2 img-rounded"
//                          width="128"
//                          height="128"
//                          src={option.preview}/>
//                 </div>
//                 <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
//                     <p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.name}`}</strong>
//                     </p>
//                     {generateBadgeRow("Body parts", option.body_parts)}<br/>
//                     {generateBadgeRow("Equipment", option.equipment.name, "success")}<br/>
//                     <span style={{"margin": "0"}}>
//                         Pace/Mets/One RM: <strong>{option.pace}</strong>/<strong>{option.mets}</strong>/
//                     </span><br/>
//                     <span style={{"margin": "0"}}>
//                         Exercise Level: <strong>{option.fitness_level}</strong>
//                     </span><br/>
//                 </div>
//             </div>
//         </components.Option>
//     );
// };

export const WorkoutBlock = (constants, loadOptions) => {
    console.log(loadOptions)
    let objCache = {}
    return {
        block_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            md: 8,
            requestNormalizer: function (data) {
                return parseInt(data.id);
            },
            responseNormalizer: (option) => {
                let block_id = option
                if (objCache[block_id] !== undefined) {
                    return objCache[block_id]
                }
                return API.get('admin', `/admin/blocks/${block_id}`)
                    .then(data => {
                        objCache[block_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                components: {Option},
                getOptionLabel: option => {
                    return `[${option['id']}] ${option['name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
            }
        },
        loop_count: {
            inputType: 'number',
            validationRules: 'required|min:0,num',
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
            md: 2
        },
        block_type: {
            ...buildSelector(constToSelectOptions(constants, 'block_type')),
            md: 2
        },
    }
}

export const WorkoutModel = (constants, loadBlocks) => {
    return {
        internal_name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        preview_url: {
            type: 'input',
            validationRules: '',
            value: '',
        },
        workout_type: buildSelector(constToSelectOptions(constants, 'workout_type'), 'select'),
        blocks: {
            type: 'table_collection',
            value: [],
            prototype: WorkoutBlock(constants, loadBlocks),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
        },

    };
}

export const WorkoutFilter = (constants) => {

    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
    }

    };
