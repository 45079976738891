import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {SpecialExerciseModel} from "../../models/YogaWbExercise";

export default class YogaSpecialExerciseAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "SpecialConnectExercises"
        this.url = "/admin/wb/special/exercises"
        this.redirectUrl = "/admin/wb/special/exercises"
    }

    getEntity = () => {
        return SpecialExerciseModel(this.context.constants);
    }
}
