import React from 'react';
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ReviewerModel} from "../../models/ReviewerInfo";


export default class ReviewerInfoAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Reviewer Info"
        this.url = "/admin/reviewer-info"
        this.state = {
            tags: []
        }
    }

    getEntity = () => {
        return ReviewerModel(this.context.constants);
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}