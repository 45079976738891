import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {BackgroundMusicFilter} from "../../models/BackgroundMusic";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import defaults from "../../defaults";

let g = defaults.userGroups

export default class BackgroundMusic extends Component {
    static contextType = ApiConstantsContext;
    constructor(props) {
        super(props);
        this.addPath = '/admin/background-music/add';
        this.editPath = '/admin/background-music/edit';
    }

    render() {
        let constants = this.context.constants;
        return (
            <List
                name="Background Music"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/background-music"
                listApiUrl='/admin/background-music'
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    tag: data,
                                }
                            }}
                            className="btn btn-primary"
                        >
                            Edit
                        </NavLink>
                    },
                    (list, data) => {
                        return <Button
                            allowedGroups={[g.admin, g.editor]}
                            className="btn btn-danger"
                            onClick={(e) => list.handleRemove(data.id, e)}>
                            Delete
                        </Button>
                    }]}
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                    {col: 'Audio url', row: 'audio_url'},

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={BackgroundMusicFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
