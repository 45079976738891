export const YogaTypeTranslationModel = (yoga_type_id, language_id, name) => {
    return {
        yoga_type_id: {
            value: yoga_type_id,
            hidden: true
        },
        language_id: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        }
    };
}

export const YogaTypeTranslationFilter = () => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        }
    };
}