import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import API from "@aws-amplify/api";
import {confirmAlert} from "react-confirm-alert";


export default class ReviewerInfo extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/reviewer-info/add';
        this.editPath = '/admin/reviewer-info/edit';
        this.state = {
            key: 0
        }
    }

    restorePassword = (event, reviewer_info_id) => {
        let button = event.target;
        let url = `/admin/reviewer-info/restore-password/${reviewer_info_id}`

        API.get('admin', url, {})
            .then(data=> {
                this.props.handleClick(`Successfully set default password`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to set default password: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };

    generateOneTimePassword = (event, reviewer_info_id) => {
        let button = event.target;
        let url = `/admin/reviewer-info/generate-otp/${reviewer_info_id}`

        API.get('admin', url, {})
            .then(data=> {
                this.props.handleClick(`Successfully generate OTP`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to generate OTP: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };

    deleteReviewerInfo = (event, reviewer_info_id) => {
        let button = event.target;
        let url = `/admin/reviewer-info/${reviewer_info_id}`
        console.log('deleteReviewerInfo')
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                API.del('admin', url, {})
                    .then(data=> {
                        this.props.handleClick(`Successfully delete reviewer info`, "success", "tr");
                        this.setState({key: Math.random()});
                    }).catch(error=>{
                        this.props.handleClick("Failed to delete reviewer info: " + JSON.stringify(error.response.data), "error", "tr");
                }).finally(() => {button.disable = false;})
              }
            },
            {
              label: 'No',
            }
          ]
        });
    };

    publishReviewerInfo = (event, reviewer_info_id, published) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/reviewer-info/${reviewer_info_id}`

        API.patch('admin', url, {'body': {"published": published}})
            .then(data=> {
                this.props.handleClick(`Successfully set published to ${published}`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to set published: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };

    render() {
        return (
            <List
                key={this.state.key}
                name="Reviewer Info"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/blocks"
                listApiUrl='/admin/reviewer-info'
                actions={[
                    (list, data) => {
                            if (data.published === true) {
                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.publishReviewerInfo(e, data.id, 'false')}
                            >
                                Unpublish
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.publishReviewerInfo(e, data.id, 'true')}
                            >
                                Publish
                            </Button>
                        }
                    },
                    (list, data) => {
                        return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.restorePassword(e, data.id)}
                            >
                                Restore Password
                        </Button>
                    },
                    (list, data) => {
                        return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.generateOneTimePassword(e, data.id)}
                            >
                                Generate One Time Password
                        </Button>
                    },
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    tag: data,
                                }
                            }}
                            className="btn btn-primary"
                        >
                            Edit
                        </NavLink>
                    },
                    (list, data) => {
                        return <Button
                                className="btn btn-danger btn-fill"
                                onClick={(e) => this.deleteReviewerInfo(e, data.id)}
                            >
                                Delete
                        </Button>
                    }
                ]}
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Email Address', row: 'email_address'},
                    {col: 'Default Password ', row: 'default_password'},
                    {col: 'One Time Password', row: 'otp'}
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
