import React from 'react';
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {EquipmentModel} from "../../models/Equipment";

export default class EquipmentAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Equipment"
        this.url = "/admin/equipment"

        this.state = {
            exercises: [],
            tags: [],
        }
    }

    getEntity = () => {
        return EquipmentModel();
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}