import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {TagsTranslationFilter} from "../../../models/TagsTranslation";

export default class BackgroundMusicTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Background Music Translations"
        this.addPath = "/admin/translations/background-music/add";
        this.editPath = "/admin/translations/background-music/edit";
        this.removeUrl = "/admin/translations/background-music";
        this.listApiUrl = "/admin/translations/background-music";
    }

    generateFilter = () => {
        return TagsTranslationFilter()
    }
}
