import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {YogaTypeTranslationModel} from "../../../models/YogaTypeTranslation";

export default class YogaTypeTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Yoga Type Translations"
        this.addPath = "/admin/translations/yoga-types/add";
        this.editPath = "/admin/translations/yoga-types/edit";
        this.removeUrl = "/admin/translations/yoga-types";
        this.listApiUrl = "/admin/translations/yoga-types";
    }

    generateFilter = () => {
        return YogaTypeTranslationModel()
    }
}
