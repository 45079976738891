import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {constList} from "../../utils/Utils";
import {MainExerciseFilter} from "../../models/YogaWbExercise";
import defaults from "../../defaults";
import Button from "react-bootstrap/lib/Button";
import {NavLink} from "react-router-dom";

let g = defaults.userGroups;

export default class YogaMainExercise extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/wb/main/exercises/add';
        this.editPath = '/admin/wb/main/exercises/edit';
    }

    render() {
        let constants = this.context.constants;
        let c = this.context.get_const;
        return (
            <List
                name="MainExercises"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/wb/main/exercises"
                listApiUrl='/admin/wb/main/exercises'
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    tag: data,
                                }
                            }}
                            className="btn btn-primary"
                        >
                                   Edit
                         </NavLink>
                    },
                    (list, data) => {
                        return <Button
                            allowedGroups={[g.admin, g.editor]}
                            className="btn btn-danger"
                            onClick={(e) => list.handleRemove(data.id, e)}>
                             Delete
                         </Button>
                     }]}

                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }
                    },
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Focuses',
                        row: 'focuses',
                        normalizer: (data) => {
                            return constList(c, data, 'focuses', 'yoga_focus');
                        }
                    },
                    {
                        col: 'Exercice_level',
                        row: 'Exercice_level',
                        normalizer: (data) => {
                            return constList(c, data, 'exercise_levels', 'fitness_level');
                        }
                    },

                    {
                        col: 'Position',
                        row: 'Position',
                        normalizer: (data) => {
                            return c('yoga_position', data.position)
                        }
                    },
                    {
                        col: 'WB Section',
                        row: 'WB Section',
                        normalizer: (data) => {
                            return constList(c, data, 'wb_sections', 'yoga_workout_block');
                        }
                    },

                    {
                        col: 'Age group',
                        row: 'age_group',
                        normalizer: (data) => {
                            return constList(c, data, 'age_group', 'age_group');
                        }
                    },
                    {
                        col: 'Bmi group',
                        row: 'bmi_group',
                        normalizer: (data) => {
                            return constList(c, data, 'bmi_group', 'bmi_group');
                        }
                    },
                    {
                        col: 'Yoga Type',
                        row: 'yoga_type',
                        normalizer: (data) => {
                            return c('yoga_type', data.yoga_type);
                        }
                    }

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={MainExerciseFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
