import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {MainExerciseModel} from "../../models/YogaWbExercise";

export default class YogaMainExerciseAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "MainExercises"
        this.url = "/admin/wb/main/exercises"
        this.redirectUrl = "/admin/wb/main/exercises"
    }

    getEntity = () => {
        return MainExerciseModel(this.context.constants);
    }
}
