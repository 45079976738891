import React, {Component} from "react";
import {API} from "aws-amplify";
import * as constants from "constants";

const ApiConstantsContext = React.createContext();

class ApiConstantsContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            constants: {},
            get_const: (key, item) => {
                if (!this.state.constants) {
                    return null;
                }
                if (item) {
                    return this.state.constants[key]['items'][item]['title'];
                }
                return this.state.constants[key]['title'];
            }
        }
    }

    componentDidMount() {
        API.get('admin', '/admin/constants')
            .then(data => {
                this.setState({
                    constants: data,
                })
            }).catch(error => {
            console.log("Error " + error);
        })
        API.get('admin', `/admin/equipment`)
                    .then(data => {
                        let items = {}
                        for (var i=0; i < data.items.length; i++) {
                            let el = data.items[i]
                            let key = el.name.toLowerCase().replace(" ", "_").replace("-", "_")
                            items[key] = {"key": key, "value": el.id, "title": el.name}
                        }
                        this.state.constants["equipment"] = {"title": "Equipment", "items": items}
                    }).catch(error => {
                        console.error(error)
                    })
    }


    render() {
        return (
            <ApiConstantsContext.Provider value={this.state}>
                {this.props.children}
            </ApiConstantsContext.Provider>
        );
    }
}


export {ApiConstantsContextProvider, ApiConstantsContext};


