import EquipmentAdd from "./EquipmentAdd";
import React from "react";

export default class EquipmentEdit extends EquipmentAdd {
    constructor(props) {
        super(props);
        this.skipPrepopulation = true
    }

    render() {
        return React.cloneElement(
            super.render(),
            {
                md: 12,
                responseData: this.locationState().tag
            }
        )
    }
}