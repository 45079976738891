import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {ExerciseTranslationFilter} from "../../../models/ExerciseTranslation";

export default class MainExerciseTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Main Exercise Translations"
        this.idColumnName = "content_id"
        this.addPath = "/admin/wb/main/translations/add";
        this.editPath = "/admin/wb/main/translations/edit";
        this.removeUrl = "/admin/wb/main/translations";
        this.listApiUrl = "/admin/wb/main/translations";
        this.uploadApiUrl = "/admin/wb/main/translations/upload";
        this.uploadAcceptType = ".csv";
        this.instructionUrl = "https://welltech.atlassian.net/wiki/spaces/YO/pages/3976102021/Upload+Main+and+Connect+Exercise+Translations+to+Content+Service+Admin"
    }

    generateFilter = () => {
        return ExerciseTranslationFilter()
    }
}
