import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {BlockFilter} from "../../models/Block";


export default class Block extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/blocks/add';
        this.editPath = '/admin/blocks/edit';

        this.state = {
            tags: []
        }
    }

    render() {
        let constants = this.context.constants;

        return (
            <List
                name="Block"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/blocks"
                listApiUrl='/admin/blocks'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                    {col: 'Duration ', row: 'duration'},
                    {
                        col: 'Exercises',
                        row: 'exercises',
                        normalizer: (data) => {
                            return data.exercises.map((a, i) =>
                                <div key={i}>{`${i + 1}. ${a.exercise_name}`}</div>)
                        }
                    }
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={BlockFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
