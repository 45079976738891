import {buildSelector, constToSelectOptions, yesNoSelector} from "../utils/Utils";
import {components} from "react-select";
import React from "react";
import {API} from "aws-amplify";


const Option = ({children, ...props}) => {
    let option = props.data
    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.internal_name}`}</strong></p>
                    <span style={{"margin": "0"}}>
                        Duration: <strong>{option.duration}</strong>
                    </span><br/>
                </div>
            </div>
        </components.Option>
    );
};

export const SeriesWorkoutsModel = (constants, loadOptions) => {
    let objCache = {}
    return {
        workout_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data.id);
            },
            responseNormalizer: (option) => {
                let workout_id = option
                if (objCache[workout_id] !== undefined) {
                    return objCache[workout_id]
                }
                return API.get('admin', `/admin/workouts/${workout_id}`)
                    .then(data => {
                        objCache[workout_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                getOptionLabel: option => {
                    return `[${option['id']}] ${option['internal_name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                components: {Option},
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
            },
            md: 5
        },
    };
}


export const SeriesFilter = (constants) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        name: {
            type: 'input',
            value: '',
        },
        body_part_group: buildSelector(constToSelectOptions(constants, 'body_part_group'),
            'select',
            undefined,
            undefined,
            4),
        fitness_level: buildSelector(constToSelectOptions(constants, 'fitness_level'),
            'select',
            undefined,
            undefined,
            4),
    }
};

export const SeriesModel = (constants, loadWorkouts, tags) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        description: {
            type: 'input',
            value: '',
        },
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        tags: {
            ...buildSelector(
                tags,
                'multiSelect',
                (data) => {
                    if (data === null) {
                return []
            }
                    return data.map(a => parseInt(a.value));
                },
                undefined,
            ),
            validationRules: ''
        },
        gender: buildSelector(constToSelectOptions(constants, 'series_gender'), 'select'),
        fitness_levels: buildSelector(constToSelectOptions(constants, 'fitness_level'), 'multiSelect'),
        body_part_groups: {...buildSelector(
                constToSelectOptions(constants, 'body_part_group'),
                'multiSelect',
            ),
            value: null,
        validationRules: ''},

        workouts: {
            type: 'table_collection',
            value: [],
            prototype: SeriesWorkoutsModel(constants, loadWorkouts),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
        },
        is_recommended: yesNoSelector(),
        special: yesNoSelector()
    }
};
