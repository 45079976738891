import React, {Component} from "react";
import List from "../../components/List/List";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {BranchesFilter} from "../../models/Branches";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import defaults from "../../defaults";
import {API} from "aws-amplify";


let g = defaults.userGroups

export default class Branches extends Component {
    static contextType = ApiConstantsContext;
    constructor(props) {
        super(props);
        this.addPath = '/admin/branches/add';
        this.editPath = '/admin/branches/edit';
        this.deleteMessage = "Are you sure you want to delete?"
        this.state = {
            key: 0,
            isPopUpLoading: false

        }
    }
    handleDelete = (list, id, event) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => list.handleRemove(id, event)
            },
            {
              label: 'No',
            }
          ]
        });
    };
    setBranchStatus = (event, branch_id, status) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/branches/${branch_id}/status`

        API.put('admin', url, {'body': {"is_published": status}})
            .then(data=> {
                this.props.handleClick(`Successfully set status to ${status}`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to set status: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };

    render() {
        return (
            <List
                key={this.state.key}
                name="Branches"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/branches"
                listApiUrl='/admin/branches'
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    tag: data,
                                }
                            }}
                            className="btn btn-primary"
                        >
                            Edit
                        </NavLink>
                    },
                    (list, data) => {
                        return <Button
                            allowedgroups={[g.admin, g.editor]}
                            className="btn btn-danger"
                            onClick={(e) => this.handleDelete(list, data.id, e)}
                        >
                            Delete
                        </Button>
                    },
                    (list, data) => {
                        if (data.is_published === true) {
                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.setBranchStatus(e, data.id, false)}
                            >
                                Unpublish
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.setBranchStatus(e, data.id, true)}
                            >
                                Publish
                            </Button>
                        }
                    },
                ]}
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Series',
                        row: 'series',
                        normalizer: (data) => {
                            return data.series.map((a, i) =>

                                <div key={i}>{`${i + 1}. ${a.series_id}`}</div>)
                        }
                    },
                    {
                        col: 'Is Published',
                        row: 'is_published',
                        normalizer: (data) => {

                            let is_published = 'no'
                            if (data.is_published === true){
                                is_published = "yes";
                            }
                            return is_published
                        }
                    }
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={BranchesFilter()}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
