import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {ExerciseTranslationFilter} from "../../../models/ExerciseTranslation";

export default class SpecialExerciseTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Special Connect Exercise Translations"
        this.idColumnName = "content_id"
        this.addPath = "/admin/wb/special/translations/add";
        this.editPath = "/admin/wb/special/translations/edit";
        this.removeUrl = "/admin/wb/special/translations";
        this.listApiUrl = "/admin/wb/special/translations";
        this.uploadApiUrl = "/admin/wb/special/translations/upload";
        this.uploadAcceptType = ".csv";
        this.instructionUrl = "https://welltech.atlassian.net/wiki/spaces/YO/pages/3976102021/Upload+Main+Connect+and+Special+Connect+Exercise+Translations+to+Yoga+Admin"
    }

    generateFilter = () => {
        return ExerciseTranslationFilter()
    }
}
