import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {ExerciseContentTranslationModel} from "../../../models/ExerciseTranslation";


export default class SpecialExerciseTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Special Connect Exercise Translation"
        this.url = "/admin/wb/special/translations"
        this.skipPrepopulation = true
    }

    locationState = () => {
        return this.props.location.state || {language: {}, translation: {}}
    }

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`
    }

    getEntity = () => {
        let state = this.locationState()
        let translation = state.translation || {}

        return ExerciseContentTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || null,
            translation.audio_name || null,
            translation.audio_instruction || null,
            translation.audio_background || null,
            translation.description

        );
    }
}
