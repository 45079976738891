import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {constList} from "../../utils/Utils";
import {SpecialExerciseFilter} from "../../models/YogaWbExercise";
import defaults from "../../defaults";
import Button from "react-bootstrap/lib/Button";
import {NavLink} from "react-router-dom";

let g = defaults.userGroups;

export default class YogaSpecialExercise extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/wb/special/exercises/add';
        this.editPath = '/admin/wb/special/exercises/edit';
    }

    render() {
        let constants = this.context.constants;
        let c = this.context.get_const;
        return (
            <List
                name="SpecialConnectExercises"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/wb/special/exercises"
                listApiUrl='/admin/wb/special/exercises'
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    tag: data,
                                }
                            }}
                            className="btn btn-primary"
                        >
                                   Edit
                         </NavLink>
                    },
                    (list, data) => {
                        return <Button
                            allowedGroups={[g.admin, g.editor]}
                            className="btn btn-danger"
                            onClick={(e) => list.handleRemove(data.id, e)}>
                             Delete
                         </Button>
                     }]}

                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }
                    },
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Exercise level',
                        row: 'Exercise level',
                        normalizer: (data) => {
                            return constList(c, data, 'exercise_levels', 'fitness_level');
                        }
                    },
                    {
                        col: 'Body Parts',
                        row: 'Body Parts',
                        normalizer: (data) => {
                            return constList(c, data, 'body_parts', 'body_part_content_service');
                        }
                    },
                    {
                        col: 'In position',
                        row: 'In position',
                        normalizer: (data) => {
                            return c('yoga_position', data.connect_block_in_position)
                        }
                    },
                    {
                        col: 'Out position',
                        row: 'Out position',
                        normalizer: (data) => {
                            return c('yoga_position', data.connect_block_out_position)
                        }
                    },
                    {
                        col: 'Equipment',
                        row: 'Equipment',
                        normalizer: (data) => {
                            return constList(c, data, 'equipment', 'equipment');
                        }
                    },
                    {
                        col: 'Yoga Type',
                        row: 'yoga_type',
                        normalizer: (data) => {
                            return c('yoga_type', data.yoga_type);
                        }
                    }

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={SpecialExerciseFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
