import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ConnectExerciseModel} from "../../models/YogaWbExercise";
import {API} from "aws-amplify";
import debounce from "debounce-promise";

export default class YogaConnectExerciseAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "ConnectExercises"
        this.url = "/admin/wb/connect/exercises"
        this.redirectUrl = "/admin/wb/connect/exercises"
        this.debouncedLoadOptions = debounce(this.loadExercises, 2000);
    }

    detectSearchParameter = input => {
        input = input.toLowerCase()

        if (!isNaN(input)) {
            return ["ids", JSON.stringify([parseFloat(input)])]
        }
        return ["name", input]
    }

    loadExercises = input => {
        let queryParams = {
            "per_page": "100"
        }

        if (input.length > 0) {
            let [searchParam, searchValue] = this.detectSearchParameter(input)
            queryParams[searchParam] = searchValue
        }
        return API.get('admin', "/admin/wb/main/exercises", {
            'queryStringParameters': queryParams
        })
            .then(data => {
                let exercises = data.items.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(exercises);
            }).catch(error => {
                console.error(error)
                return []
            })
    }

    getEntity = () => {
        return ConnectExerciseModel(this.context.constants,
            inputValue => this.debouncedLoadOptions(inputValue));
    }
}
