export const UserTokenFilter = {
    user_id: {
        type: 'input',
        inputType: 'number',
        value: '',
        placeholder: 'User id'
    },
    email: {
        type: 'input',
        value: '',
        placeholder: 'User email'
    }
}