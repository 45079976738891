import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import Button from "react-bootstrap/lib/Button";
import {ConnectExerciseFilter} from "../../models/YogaWbExercise";
import {NavLink} from "react-router-dom";
import defaults from "../../defaults";

let g = defaults.userGroups;


export default class YogaConnectExercise extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/wb/connect/exercises/add';
        this.editPath = '/admin/wb/connect/exercises/edit';
    }

    render() {
        let constants = this.context.constants;
        let c = this.context.get_const;
        return (
            <List
                name="ConnectExercises"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/wb/connect/exercises"
                listApiUrl='/admin/wb/connect/exercises'
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    tag: data,
                                }
                            }}
                            className="btn btn-primary"
                        >
                                   Edit
                         </NavLink>
                    },
                    (list, data) => {
                        return <Button
                            allowedGroups={[g.admin, g.editor]}
                            className="btn btn-danger"
                            onClick={(e) => list.handleRemove(data.id, e)}>
                             Delete
                         </Button>
                     }]}

                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }
                    },
                    {col: 'Name', row: 'name'},

                    {
                        col: 'Transition pose',
                        row: 'wb_transition_pose',
                        normalizer: (data) => {
                            return c('yoga_transition_pose', data.wb_transition_pose)
                        }
                    },
                    {col: 'Connect_id', row: 'connect_id'},
                    {col: 'Connect_name', row: 'connect_name'},

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={ConnectExerciseFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
